import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { useI18next } from 'gatsby-plugin-react-i18next'

import Button from './button'

const PartnerCard = ({ pic, path }) => {
  const { t } = useI18next()

  const data = useStaticQuery(graphql`
    query PartnerCardImages {
        images: allFile(filter: { relativeDirectory: { eq: "references" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(pic))

  return (
      <div className="partner-card">
          <div className="partner-card-img-wrapper">
              <Img fluid={image.childImageSharp.fluid} />
          </div>
          <div className="partner-card-btn-container-wrapper">
              <div className="partner-card-btn-container">
                  <Button color="white" text={t('visit')} path={path} external />
              </div>
          </div>
      </div>
  )
}

PartnerCard.propTypes = {
  pic: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default PartnerCard
