import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Partners from '../components/partners'
import PartnerCard from '../components/partner_card'

const ReferencesPage = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title={t('title')} language={i18n.language} />
          <Partners>
              <PartnerCard pic="01_BME" path="https://www.bme.hu/" />
              <PartnerCard pic="02_ELTE" path="https://www.elte.hu/" />
              <PartnerCard pic="03_TELEKOM" path="https://www.telekom.hu/" />
              <PartnerCard pic="04_TELENOR" path="https://www.telenor.hu/" />
              <PartnerCard pic="05_VODAFONE" path="https://www.vodafone.hu/" />
              <PartnerCard pic="06_INVITECH" path="https://www.invitech.hu/" />
              <PartnerCard pic="07_SIEMENS" path="https://www.siemens.com/" />
              <PartnerCard pic="08_ERICSSON" path="https://www.ericsson.com/" />
              <PartnerCard pic="09_NOKIA" path="https://www.nokia.com/" />
              <PartnerCard pic="10_ARROWHEAD" path="https://www.arrowhead.eu/" />
          </Partners>
      </Layout>
  )
}

export default ReferencesPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "references", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
