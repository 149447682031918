import React from 'react'
import PropTypes from 'prop-types'
import { Link, useI18next } from 'gatsby-plugin-react-i18next'

export default function Button({
  color, text, path, external,
}) {
  const { t } = useI18next()

  return (
      <>
          { external
            ? <a className={`btn btn--${color}`} href={path}>{t(text).toUpperCase()}</a>
            : <Link className={`btn btn--${color}`} to={path}>{t(text).toUpperCase()}</Link>}
      </>

  )
}

Button.propTypes = {
  color: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  external: PropTypes.bool,
}

Button.defaultProps = {
  external: undefined,
}
