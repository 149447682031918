import React from 'react'
import PropTypes from 'prop-types'
import { useI18next } from 'gatsby-plugin-react-i18next'

const Partners = ({ children }) => {
  const { t } = useI18next()

  return (
      <div className="page-section">
          <div className="page-section-title page-section-title-large">
              <p>{t('title').toUpperCase()}</p>
          </div>
          <div className="page-section-content">
              <div className="page-section-content-partners">
                  {children}
              </div>
          </div>
      </div>
  )
}

Partners.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Partners
